// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/ui/icons/alert-icons/AlertTriangleOutline.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/ui/icons/alert-icons/AlertTriangleOutline.tsx");
  import.meta.hot.lastModified = "1726176605631.3848";
}
// REMIX HMR END

import { AlertTriangle } from 'lucide-react';
import { forwardRef } from 'react';
import colors from 'tailwindcss/colors.js';
const variants = {
  success: colors.green[600],
  info: colors.blue[600],
  warning: colors.amber[500],
  error: colors.red[600]
};
export const AlertTriangleOutline = forwardRef(_c = ({
  width,
  height,
  size = 20,
  variant = 'info',
  ...props
}, ref) => {
  const color = variants[variant];
  return <AlertTriangle ref={ref} color={color} width={width ?? size} height={height ?? size} {...props} />;
});
_c2 = AlertTriangleOutline;
var _c, _c2;
$RefreshReg$(_c, "AlertTriangleOutline$forwardRef");
$RefreshReg$(_c2, "AlertTriangleOutline");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;