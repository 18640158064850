// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/ui/icons/alert-icons/AlertCircleFilled.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/ui/icons/alert-icons/AlertCircleFilled.tsx");
  import.meta.hot.lastModified = "1726176605631.3848";
}
// REMIX HMR END

import { forwardRef } from 'react';
import colors from 'tailwindcss/colors.js';
const variants = {
  success: colors.green[600],
  info: colors.blue[600],
  warning: colors.amber[500],
  error: colors.red[600]
};
export const AlertCircleFilled = forwardRef(_c = ({
  width,
  height,
  size = 20,
  variant = 'info',
  ...props
}, ref) => {
  return <svg ref={ref} width={width ?? size} height={height ?? size} viewBox='0 0 24 24' fill='none' {...props}>
      <path fillRule='evenodd' clipRule='evenodd' d='M2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12ZM13 11C13 10.4477 12.5523 10 12 10C11.4477 10 11 10.4477 11 11V16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16V11ZM13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8Z' fill={variants[variant]} />
    </svg>;
});
_c2 = AlertCircleFilled;
var _c, _c2;
$RefreshReg$(_c, "AlertCircleFilled$forwardRef");
$RefreshReg$(_c2, "AlertCircleFilled");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;